.scroll {
  white-space: nowrap; /* [1] */
  overflow-x: auto; /* [2] */
  -webkit-overflow-scrolling: touch; /* [3] */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */
}

/* [5] */
.scroll::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  color: #3f3e3e;
  font-family: SulSans, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-scroll-up {
  display: block;
  position: fixed;
  z-index: 999999;
  padding: 15px 5px 15px 5px;
  border-radius: 100px;
}

.btn-scroll-down {
  display: block;
  position: fixed;
  z-index: 999999;
  padding: 15px 5px 15px 5px;
  border-radius: 100px;
}

.btn-scroll-up svg {
  width: 2em;
  height: 2em;
}

.btn-scroll-down svg {
  width: 2em;
  height: 2em;
}

h1 {
  font-size: 1.25rem;
}

h2 {
  font-size: 1.125rem;
}

h3 {
  font-size: 1.1rem;
}

p {
  line-height: normal;
}

.text-navbar {
  text-align: center;
  white-space: normal;
}

.user-select-none {
  user-select: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

img.image-fit-cover {
  width: 100%;
  object-fit: cover;
}

.fixed-logo-figure {
  width: 150px !important;
  height: 150px !important;
}

input.hidden-step::-webkit-outer-spin-button,
input.hidden-step::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].hidden-step {
  -moz-appearance: textfield;
}

.btn.btn-link:disabled {
  color: #6c757d;
}

.product-screen--stretched {
  padding-bottom: 100px;
}

.shop-home-screen--stretched {
  padding-bottom: 100px;
}

.products-highlights {
  overflow: hidden;
}

.products-highlights-content {
  width: 100vw;
  overflow-x: auto;
}

.products-highlights-content .div-empty {
  min-width: 200px;
}

.products-highlights-content .product-short-card.card {
  min-width: 160px;
}

.grouped-products-instagram {
  padding-top: 120px;
}

.products-details {
  word-wrap: break-word;
}

.table-status th {
  border-top: 0 none;
  border-bottom: 0 none;
}

.table-status td {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.border-none {
  border: 0 none;
}

.border-x-none {
  border-right: 0 none;
  border-left: 0 none;
}

.border-x-none:first-child {
  border-top: none;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  border-radius: 100px;
  position: relative;
  width: 100%;
  max-width: 150px;
}

.avatar:before {
  content: "";
  float: left;
  padding-top: 100%;
}

.avatar_initials {
  color: white;
  font-size: 36px;
  line-height: 100px;
  margin: 0;
  padding: 0;
}

.avatar_container_shop_header {
  width: 150px;
  height: 150px;
}

.card-header:first-child {
  border-radius: 1.25rem;
}

.btn-image {
  border-radius: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.accordion > .card > .btn-image {
  border-radius: 1.5rem;
}

.accordion > .card > .btn-image > .btn-image-label:focus-visible,
.accordion > .card > .btn-image > .btn-image-label:focus {
  box-shadow: unset;
  outline: none;
}

.btn-image .btn-image-label {
  border-radius: 1.5rem;
}

.group-menu__item {
  font-size: 0.75rem;
}

.group-menu__image-container {
  border: 2px solid red;
  height: 58px;
  width: 58px;
}

.group-menu__image {
  height: 50px;
  width: 50px;
  background: transparent no-repeat center;
  background-size: cover;
}

.group-menu__image .avatar_initials {
  font-size: 1.5rem;
}

.nav-instagram-menu {
  overflow: auto;
  white-space: nowrap;
}

.nav-instagram-menu .nav-item {
  display: inline-block;
}

.nav-instagram-menu .nav-item a {
  color: black;
}

.totem-root-div {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  width: 100vw;
}

.totem-home-groups-column {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 20%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.totem-home-products-column {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
}

.totem-question-options-column {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
}

.totem-group-image {
  width: 80%;
  padding-top: 80%;
  background: transparent no-repeat 50%;
  background-size: cover;
}

.totem-products-container {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
}

.totem-product-card {
  background-color: white;
}

.totem-product-card-0 {
  margin-bottom: 4vh;
  margin-left: 2vw;
  margin-right: 1vw;
  background-color: white;
}

.totem-product-card-1 {
  margin-bottom: 4vh;
  margin-left: 1vw;
  margin-right: 2vw;
  background-color: white;
}

.grouped-products-totem-view__logo-avatar,
.grouped-products-totem-view__logo-figure {
  width: 80px;
  height: 80px;
  margin: auto;
  margin-bottom: 15px;
}

.grouped-products-totem-view__navbar .nav-item {
  width: 100%;
}

.totem-cart-total {
  font-size: 2.5rem;
  font-weight: 900;
}

.totem-cart-product-name {
  font-size: 1.5rem;
}

.totem-cart-product-price {
  font-size: 1.5rem;
  white-space: nowrap;
}

.simple-keyboard.hg-theme-default.totem {
  background-color: transparent;
  border-radius: 10px;
  margin-top: 1rem;
}

.simple-keyboard.hg-layout-default .hg-button.totem-numpad {
  background: lightgray;
  height: 80px;
  font-size: 2rem;
}

.simple-keyboard.hg-layout-default .hg-button.totem-keyboard {
  background: lightgray;
  height: 40px;
  font-size: 1.5rem;
}

.simple-keyboard.hg-layout-default .hg-button.totem-back-space {
  background: rgb(156, 156, 156);
}

.totem-group-menu__image-container {
  height: 162px;
  width: 162px;
}

.totem-group-menu__image {
  height: 120px;
  width: 120px;
  background: transparent no-repeat center;
  background-size: cover;
}

.totem-group-name {
  font-size: 1rem;
}

.totem-back-button svg {
  width: 2em;
  height: 2em;
}

.totem-header {
  display: flex;
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.totem-header p {
  margin: 0;
  padding: 0;
}

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.9;
  }
  
  to {
    opacity: 1;
    scale: 1;
  }
}

.totem-option-image {
  max-height: 15vh;
  object-fit: contain;
  animation: appear linear;
  animation-duration: 0.5s;
}

.totem-home-product-image {
  max-height: 15vh;
  object-fit: contain;
  animation: appear linear;
  animation-duration: 0.5s;
}

.totem-home-product-name {
  font-size: 1rem;
  font-weight: 900;
}

.totem-home-product-details {
  font-size: small;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
          line-clamp: 4; 
  -webkit-box-orient: vertical;
}

.totem-home-product-price {
  font-size: 1rem;
  font-weight: 900;
  text-align: right;
}

.checkout-dialog-body {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  padding: 50dp;
  overflow: hidden;
}

.checkout-dialog-body-div {
  max-width: 600px;
  margin: auto;
}

.product-questions-dialog-body {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.totem-big-button {
  font-size: 1rem;
  font-weight: 900;
}

.totem-cart-button {
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 3vh;
  padding-top: 3vh;
  margin-left: 25vw;
  margin-right: 5vw;
}

.totem-product-image {
  max-height: 40vh;
  object-fit: contain;
}

.totem-product-name {
  font-size: 1.5rem;
  font-weight: 900;
}

.totem-product-details {
  font-size: 1rem;
  font-weight: 500;
}

.totem-product-price {
  font-size: 1.3rem;
  font-weight: 500;
}

.totem-product-button-add {
  font-size: 1.5rem;
  font-weight: 900;
}

.totem-product-button-qtt svg {
  width: 2em;
  height: 2em;
}

.totem-question-button-qtt svg {
  width: 1.5em;
  height: 1.5em;
}

.totem-option-item {
  border: solid;
  border-color: white;
  border-width: 3px;
  border-radius: 10px;
  box-shadow: 0 0rem 2rem rgba(0,0,0,.15)!important;
}

.totem-checkout-button {
  font-size: 1.5rem;
  font-weight: 900;
  padding: 1em;
}

.totem-checkout-back-button {
  font-size: 1.3rem;
  font-weight: 900;
  padding: 0.8em;
}

.totem-checkout-button svg {
  width: 3em;
  height: 3em;
  margin-top: 1em;
}

.totem-checkout-spinner {
  width: 10em;
  height: 10em;
}

.modal-content {
  border: none;
  border-radius: 0;
}

.text-selected {
  font-weight: 700!important;
  text-decoration: underline;
  font-style: italic;
}

.inloco-status {
  max-height: 80vh;
  max-width: 500px;
  overflow-y: scroll;
  margin: auto;
  align-content: center;
  background-color: #dee2e6;
  border-radius: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}

.background-pattern {
  background-image: url(app/assets/img/pattern.svg);
  background-color: black;
  height: 100vh;
  width: 100vw;
  padding: 1em;
}

@media (orientation: landscape) {
  .totem-home-groups-column {
    width: 13%;
  }

  .totem-home-products-column {
    width: 87%;
  }
}

@media screen and (min-width: 1024px) {
  .group-menu__image-container {
    height: 78px;
    width: 78px;
  }

  .group-menu__image {
    height: 70px;
    width: 70px;
  }

  .grouped-products-instagram {
    padding-top: 140px;
  }
  
  ::-webkit-scrollbar {
    width: 0px;
  }
}

@media screen and (min-width: 768px) {

  .totem-header {
    font-size: 2rem;
  }

  .totem-group-name {
    font-size: 1.2rem;
  }

  .totem-home-product-name {
    font-size: 1.5rem;
  }
  
  .totem-home-product-details {
    font-size: medium;
  }
  
  .totem-home-product-price {
    font-size: 1.5rem;
  }

  .totem-product-details {
    font-size: 1.3rem;
    font-weight: 500;
  }
  
  .totem-big-button {
    font-size: 2rem;
  }  

  .totem-cart-button {
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 2vh;
    padding-top: 2vh;
    margin-left: 25vw;
    margin-right: 5vw;
  }
  
  .totem-checkout-button {
    font-size: 2rem;
    padding: 1em;
  }

  .totem-checkout-button svg {
    width: 5em;
    height: 5em;
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .totem-checkout-back-button {
    font-size: 2rem;
    padding: 1em;
  }

}